import React, { useEffect } from "react";
import { CustomDialog } from "../../components/custom-dialog";
import PropTypes from "prop-types";
import { Box, Button, Link, MenuItem, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import Image from "../../components/image";
import { RHFSelect } from "../../components/hook-form/rhf-select";

import { fCurrency } from "../../utils/format-number";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { listCardType, listPackage } from "../../constants/main";
import { checkCard } from "../../utils/check-card";
import { useRechargeMutation } from "../../core/user/user.query";
import { useSnackbar } from "../../components/snackbar";

const ExchangeSchema = yup.object().shape({
  package: yup.string().required("Vui lòng chọn mệnh giá thẻ"),
  serial: yup.string().required("Vui lòng nhập seri thẻ"),
  card: yup.string().required("Vui lòng nhập mã thẻ"),
  type: yup.string(),
});

const ExchangeDialog = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(ExchangeSchema),
    defaultValues: {
      type: "",
      package: 500000,
      serial: "",
      card: "",
    },
  });

  const { mutateAsync, data } = useRechargeMutation();

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
    setValue,
    reset,
  } = methods;

  const value = watch();

  useEffect(() => {
    setValue("package", 500000);
  }, []);

  useEffect(() => {
    if (!data) return;
    // console.log(data);
    onClose();
  }, [data]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (checkCard(data)) {
        // console.log(data);

        await mutateAsync({
          telco:
            data.type.charAt(0).toUpperCase() +
            data.type.slice(1).toLowerCase(),
          amount: data.package,
          serial: data.serial,
          code: data.card,
        })
          .then((res) => {
            enqueueSnackbar("Nạp thành công", {
              variant: "success",
            });
            reset({
              type: "",
              package: 500000,
              serial: "",
              card: "",
            });
            onClose();
          })
          .catch((error) => {
            enqueueSnackbar("Nạp thất bại", {
              variant: "error",
            });
          });
      } else {
        enqueueSnackbar("Sai định dạng card", {
          variant: "error",
        });
      }
    } catch (error) {
      // console.error(error);
      enqueueSnackbar("Có lỗi xảy ra", {
        variant: "error",
      });
    }
  });

  return (
    <CustomDialog
      size={"xs"}
      title={"Nạp thẻ cào"}
      open={open}
      onClose={() => {
        onClose();
        reset({
          type: "",
          package: 500000,
          serial: "",
          card: "",
        });
      }}
      content={
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack spacing={5} pb={3.5} pt={2}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Typography component="span" variant="subtitle2">
                  Nhà mạng (Ưu tiên Viettel, Vinaphone)
                </Typography>
                <Box
                  display={"grid"}
                  gridTemplateColumns="repeat(3, 1fr)"
                  rowGap={1.5}
                  columnGap={1.5}
                >
                  {listCardType.map((item, index) => (
                    <Button
                      key={index}
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      sx={{
                        borderColor: (theme) =>
                          value.type === item.value
                            ? theme.palette.primary.main
                            : theme.palette.secondary.main,
                        borderStyle: "dashed",
                        py: 1.5,
                        borderRadius: 0.5,
                        "&:hover": {
                          borderColor: (theme) => theme.palette.primary.main,
                          borderStyle: "dashed",
                          bgcolor: "transparent",
                        },
                      }}
                      onClick={() => methods.setValue("type", item.value)}
                    >
                      <Image
                        src={item.image}
                        alt={item.value}
                        sx={{
                          width: 1,
                          height: 30,
                          maxWidth: 70,
                          filter:
                            value.type === item.value
                              ? "none"
                              : "grayscale(100%)",
                          "& .css-3j6ntj": {
                            objectFit: "contain",
                          },
                        }}
                      />
                    </Button>
                  ))}
                </Box>
              </Stack>
              <Stack spacing={1}>
                <Typography component="span" variant="subtitle2">
                  Mệnh giá
                </Typography>
                <RHFSelect
                  name="package"
                  label="Chọn mệnh giá thẻ"
                  size={"small"}
                  disabled={!Boolean(value.type)}
                  sx={{
                    "& .Mui-disabled": {
                      bgcolor: "secondary.lighter",
                      "&:hover fieldset": {
                        borderColor: "#D8DBE0 !important",
                      },
                    },
                    "& .MuiInputBase-root": {
                      borderRadius: 0.5,
                      "&:hover fieldset": {
                        borderColor: "primary.main",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                >
                  {listPackage.map((item) => (
                    <MenuItem value={item} key={item}>
                      {fCurrency(item)}đ ( Nhận 100% )
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              <Box
                display={"grid"}
                gridTemplateColumns="repeat(2, 1fr)"
                rowGap={2}
                columnGap={2}
              >
                <Stack spacing={1}>
                  <Typography component="span" variant="subtitle2">
                    Seri
                  </Typography>
                  <RHFTextField
                    name="serial"
                    disabled={!Boolean(value.package) || !Boolean(value.type)}
                    size={"small"}
                    sx={{
                      "& .Mui-disabled": {
                        bgcolor: "secondary.lighter",
                        "&:hover fieldset": {
                          borderColor: "#D8DBE0 !important",
                        },
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: 0.5,
                        "&:hover fieldset": {
                          borderColor: "primary.main",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "primary.main",
                        },
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography component="span" variant="subtitle2">
                    Mã thẻ
                  </Typography>
                  <RHFTextField
                    name="card"
                    disabled={!Boolean(value.package) || !Boolean(value.type)}
                    size={"small"}
                    sx={{
                      "& .Mui-disabled": {
                        bgcolor: "secondary.lighter",
                        "&:hover fieldset": {
                          borderColor: "#D8DBE0 !important",
                        },
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: 0.5,
                        "&:hover fieldset": {
                          borderColor: "primary.main",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "primary.main",
                        },
                      },
                    }}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack spacing={1.5}>
              <Box>
                <LoadingButton
                  color="primary"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{
                    px: 5,
                    borderRadius: 0.5,
                  }}
                >
                  Nạp tiền
                </LoadingButton>
              </Box>
              <Typography
                component="span"
                variant="caption"
                fontStyle={"italic"}
                fontSize={13}
              >
                Xem danh sách nạp thẻ{" "}
                <Link color={"primary"} variant="subtitle2">
                  tại đây
                </Link>{" "}
                (click)
              </Typography>

              <Typography
                component="span"
                variant="body3"
                color="primary"
                fontWeight={600}
              >
                Lưu ý: Theo quy định của nhà mạng, nạp sai mệnh giá sẽ bị mất
                thẻ.
              </Typography>
            </Stack>
          </Stack>
        </FormProvider>
      }
    />
  );
};

ExchangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExchangeDialog;
