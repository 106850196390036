import { useMutation } from "@tanstack/react-query";
import { login, register } from "./auth.api";
import { queryClient } from "../../App";

export const useLoginMutation = () =>
  useMutation({
    mutationFn: login,
    async onSuccess(data) {
      void queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

export const useRegisterMutation = () =>
  useMutation({
    mutationFn: register,
    async onSuccess(data) {
      void queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
