import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HOME, paths } from "../../routers/paths";
import Iconify from "../../components/iconify";
import { RouterLink } from "../../routers/components";

import { usePathname, useRouter } from "../../routers/hooks";
import { useBoolean } from "../../hooks/use-boolean";
import ExchangeDialog from "./ExchangeDialog";
import AuthDialog from "./AuthDialog";
import { usePopover } from "../../components/custom-popover";
import { m } from "framer-motion";
import CustomPopover from "../../components/custom-popover/custom-popover";
import TabExchangeDialog from "./TabExchangeDialog";

const loginData = {
  username: "admin",
  password: "admin",
};

const BottomNavbar = () => {
  const pathName = usePathname();

  const popover = usePopover();

  const exchange = useBoolean();

  const router = useRouter();

  const auth = useBoolean();

  const [formAuth, setFormAuth] = useState(null);
  const [formExchange, setFormExchange] = useState(null);

  const handleExchange = () => {
    const token = sessionStorage.getItem("accessToken");
    if (!token) return auth.onTrue();
    exchange.onTrue();
  };

  useEffect(() => {
    if (!formAuth) return;

    if (formAuth.type === "login") {
      if (
        formAuth.username === loginData.username &&
        formAuth.password === loginData.password
      ) {
        sessionStorage.setItem("token", "token");
      }
    }
  }, [formAuth]);

  useEffect(() => {
    if (!formExchange) return;
    // console.log(formExchange);
  }, [formExchange]);

  return (
    <>
      <Box
        position={"fixed"}
        bottom={0}
        left={0}
        bgcolor={"white"}
        py={1.25}
        width={1}
      >
        <Box display={"grid"} gridTemplateColumns="repeat(3, 1fr)">
          <Button
            variant="text"
            LinkComponent={RouterLink}
            sx={{
              p: 0,
              py: 0.25,
              ...(pathName === HOME && {
                color: "primary.main",
              }),
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            href={HOME}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Iconify icon="bx:home-circle" width={24}></Iconify>
              <Typography component="span" variant="subtitle2">
                Trang chủ
              </Typography>
            </Stack>
          </Button>
          {/* <Button
            variant="text"
            component={m.button}
            sx={{
              p: 0,
              py: 0.25,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={popover.onOpen}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Iconify icon="bx:search" width={24}></Iconify>
              <Typography component='span'variant="subtitle2">Tìm kiếm</Typography>
            </Stack>
          </Button> */}
          <Button
            variant="text"
            sx={{
              p: 0,
              py: 0.25,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={handleExchange}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Iconify icon="bx:wallet-alt" width={24}></Iconify>
              <Typography component="span" variant="subtitle2">
                Nạp tiền
              </Typography>
            </Stack>
          </Button>
          <Button
            variant="text"
            sx={{
              p: 0,
              py: 0.25,
              ...(Object.values(paths.customer).includes(pathName) && {
                color: "primary.main",
              }),
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              const token = sessionStorage.getItem("accessToken");
              if (!token) return auth.onTrue();
              router.push(paths.customer.root);
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Iconify
                icon={
                  Object.values(paths.customer)
                    .filter((item) => item !== paths.customer.root)
                    .includes(pathName)
                    ? "bx:subdirectory-left"
                    : "bx:user"
                }
                width={24}
              ></Iconify>
              {Object.values(paths.customer)
                .filter((item) => item !== paths.customer.root)
                .includes(pathName) ? (
                <Typography component="span" variant="subtitle2">
                  Quay lại
                </Typography>
              ) : (
                <Typography component="span" variant="subtitle2">
                  Tài khoản
                </Typography>
              )}
            </Stack>
          </Button>
        </Box>
      </Box>

      <AuthDialog
        open={auth.value}
        onClose={auth.onFalse}
        setForm={setFormAuth}
      />

      <TabExchangeDialog
        open={exchange.value}
        onClose={exchange.onFalse}
        setForm={setFormExchange}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          backgroundImage: "none",
          bgcolor: "white",
          "& .MuiPopover-paper": {
            backgroundImage: "none",
            bgcolor: "white",
          },
        }}
        arrow="bottom-center"
      >
        <Stack spacing={1.5} p={1}>
          <Typography component="span" variant="body1" color={"grey.700"}>
            Tìm kiếm
          </Typography>
          <TextField
            size="small"
            fullWidth
            placeholder="Nhập tìm kiếm..."
            InputProps={{
              endAdornment: (
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    width: 110,
                  }}
                >
                  Tìm kiếm
                </Button>
              ),
            }}
            sx={{
              borderRadius: 0.5,
              "& .MuiInputBase-root": {
                "&:hover fieldset": {
                  borderWidth: 1,
                  borderColor: "primary.main",
                },
                "&:focus fieldset": {
                  borderWidth: 1,
                  borderColor: "primary.main",
                },
              },
            }}
          />
        </Stack>
      </CustomPopover>
    </>
  );
};

export default BottomNavbar;
