import { useQuery } from "@tanstack/react-query";
import { getDetailCategory, getListCategories } from "./category.api";

export const useGetCategoriesQuery = ({
  page_size,
  page_number,
  account_type,
  game_type_id,
}) =>
  useQuery({
    queryKey: ["category"],
    queryFn: () =>
      getListCategories({
        page_size,
        page_number,
        account_type,
        game_type_id,
      }),
  });

export const useGetDetailCategoryQuery = (id) =>
  useQuery({
    queryKey: ["detail-category", id],
    queryFn: () => getDetailCategory(id),
  });
