import { default as base } from "axios";

const headers = {
  "Content-Type": "application/json",
};

const axios = base.create({
  baseURL: "https://api.shopducmomtv.net/api/v1",
  headers,
});

export default axios;
