export const HOME = "/";

export const paths = {
  category: {
    root: "/kham-pha",
    detail: (id) => `/kham-pha/${id}`,
  },
  account: {
    root: "/tai-khoan",
    list: (id) => `/tai-khoan/${id}`,
    detail: (id) => `/tai-khoan/chi-tiet/${id}`,
  },
  customer: {
    root: "/customer",
    profile: "/customer/profile",
    notification: "/customer/notifications",
    transaction: "/customer/transactions",
    nicks: "/customer/nicks",
  },
};
