import axios from "../../lib/ClientApi";
import { setSession } from "../../lib/utils";

export const login = async ({ username, password }) =>
  axios
    .post("/login", {
      username,
      password,
    })
    .then((res) => {
      const { access_token } = res.data;
      setSession(access_token);

      return res.data;
    });

export const register = async ({ username, password }) =>
  axios.post("/register", { username, password }).then((res) => res.data);
