import React from "react";
import { CustomDialog } from "../../components/custom-dialog";
import ExchangeDialog from "./ExchangeDialog";
import { useBoolean } from "../../hooks/use-boolean";
import { Button, Stack } from "@mui/material";
import Iconify from "../../components/iconify";
import ATMExchangeDialog from "./ATMExchangeDialog";

const TabExchangeDialog = ({ open, onClose }) => {
  const cardExchange = useBoolean();
  const atmExchange = useBoolean();

  return (
    <>
      <CustomDialog
        size={"xs"}
        title={"Chọn hình thức nạp"}
        open={open}
        onClose={() => {
          onClose();
        }}
        content={
          <Stack spacing={1.5} pb={3.5}>
            <Stack spacing={1.5} pt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 1,
                  padding: 2,

                  fontSize: 18,
                }}
                onClick={() => {
                  cardExchange.onTrue();
                  onClose();
                }}
                startIcon={
                  <Iconify
                    icon="heroicons:credit-card-20-solid"
                    width={24}
                  ></Iconify>
                }
              >
                Thẻ cào
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 1,
                  padding: 2,
                  fontSize: 18,
                }}
                onClick={() => {
                  atmExchange.onTrue();
                  onClose();
                }}
                startIcon={<Iconify icon="bx:bxs-bank" width={24}></Iconify>}
              >
                Ngân hàng (ATM)
              </Button>
            </Stack>
          </Stack>
        }
      />

      <ExchangeDialog
        open={cardExchange.value}
        onClose={cardExchange.onFalse}
      />

      <ATMExchangeDialog
        open={atmExchange.value}
        onClose={atmExchange.onFalse}
      ></ATMExchangeDialog>
    </>
  );
};

export default TabExchangeDialog;
