import React, { useEffect } from "react";
import Header from "./components/Header";
import Bottom from "./components/Bottom";
import Main from "./components/Main";
import {
  Box,
  Link,
  useTheme,
} from "@mui/material";
import Notification from "./components/notification";
import { usePathname } from "../routers/hooks";
import { HOME } from "../routers/paths";
import Footer from "./components/footer";
import { useBoolean } from "../hooks/use-boolean";
import Image from "../components/image";
import { useResponsive } from "../hooks/use-responsive";
import BottomNavbar from "./components/BottomNavbar";
// import { linkMess } from "../constants/link";
import { useScrollToTop } from "../hooks/use-scroll-to-top";
import { useGetSystemSettingQuery } from "../core/setting/setting.query";

const Layout = ({ children }) => {
  const theme = useTheme();
  const pathName = usePathname();

  const showDialog = useBoolean();

  const mdUp = useResponsive("up", "md");

  const { data } = useGetSystemSettingQuery();

  useEffect(() => {
    showDialog.onTrue();
  }, []);

  useScrollToTop();

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.default",
          pt: 8,
        }}
        position={"relative"}
      >
        <Box
          position={"fixed"}
          top={0}
          sx={{
            width: 1,
            zIndex: 100,
            bgcolor: "background.default",
            py: 1,
          }}
        >
          <Header></Header>
        </Box>
        {pathName === HOME && (
          <Box
            sx={{
              bgcolor: "#fde047",
              width: 1,
            }}
          >
            <Notification></Notification>
          </Box>
        )}

        <Box
          sx={{
            bgcolor: theme.palette.grey[200],
            height: 1,
            width: 1,
            pb: 7,
          }}
        >
          <Main>{children}</Main>
        </Box>
        <Footer></Footer>
        <Bottom></Bottom>

        {!mdUp && <BottomNavbar></BottomNavbar>}
      </Box>

      {/* <CustomDialog
        open={showDialog.value}
        onClose={showDialog.onFalse}
        title={
          <Stack direction={"row"} spacing={1}>
            <Iconify
              icon="bxs:bell-ring"
              width={24}
              sx={{
                color: "primary.main",
              }}
            />
            <Typography
              textTransform={"uppercase"}
              color={"primary"}
              fontFamily={"System-ui"}
              fontWeight={700}
              fontSize={16}
            >
              Thông báo
            </Typography>
          </Stack>
        }
        content={
          <Stack spacing={0.5} textAlign={"center"} py={1.5} pb={2.5}>
            <Stack spacing={0.5} textAlign={"center"} px={3}>
              <Typography component="span" variant="subtitle2">
                🔥 SĂN TÀI KHOẢN GIẢM GIÁ HÀNG NGÀY - Tài Khoản{" "}
                <Link color={"primary"}>Ngon</Link>, Giảm Giá{" "}
                <Link color={"primary"}>Shock</Link>
              </Typography>
              <Typography component="span" variant="subtitle2">
                Mọi người cần hỗ trợ nhắn tin page mới nhé:{" "}
                <Link color={"#0284c7"} component={"a"} href={linkMess}>
                  TẠI ĐÂY (click)
                </Link>
              </Typography>
            </Stack>
            <Typography component="span" variant="subtitle2" color={"primary"}>
              Nếu bạn thích tài khoản nào mà chưa mua có thể bấm Thêm vào giỏ
              hàng rồi vào Tài Khoản để xem
            </Typography>
            <Image
              src="/images/noti-sale.png"
              alt="noti-sale"
              sx={{
                width: 1,
                height: 1,
                "& .css-3j6ntj": {
                  objectFit: "contain",
                },
              }}
            />

            <Box display={"flex"} justifyContent={"flex-end"} mt={4}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={showDialog.onFalse}
                size="small"
                sx={{
                  pt: 0.5,
                  px: 2,
                  borderRadius: 0.5,
                  "&:hover": {
                    bgcolor: alpha(theme.palette.primary.light, 0.1),
                    color: "primary.main",
                    borderColor: "primary.main",
                  },
                }}
              >
                Tắt thông báo
              </Button>
            </Box>
          </Stack>
        }
      /> */}

      <Link
        sx={{
          zIndex: 100,
          position: "fixed",
          right: 50,
          bottom: 150,
        }}
        component={"a"}
        href={data?.mess_support_link}
      >
        <Image
          src="/images/mes.png"
          alt="mes"
          sx={{
            width: 1,
            height: 1,
            maxWidth: 50,
          }}
        />
      </Link>
    </>
  );
};

export default Layout;
