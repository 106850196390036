import { useQuery } from "@tanstack/react-query";
import { getSetting, getSystemSetting } from "./setting.api";

export const useGetSettingQuery = () =>
  useQuery({
    queryKey: ["setting"],
    queryFn: getSetting,
  });

export const useGetSystemSettingQuery = () => useQuery({
  queryKey: ["system-setting"],
  queryFn: getSystemSetting,
})
