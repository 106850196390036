import { useMutation, useQuery } from "@tanstack/react-query";
import { getMe, recharge, updatePassword, updateProfile } from "./user.api";
import { queryClient } from "../../App";

export const useGetMeQuery = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: getMe,
  });

export const useUpdateProfileMutation = () =>
  useMutation({
    mutationFn: updateProfile,
    async onSuccess(data) {
      void queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

export const useUpdatePasswordMutation = () =>
  useMutation({
    mutationFn: updatePassword,
    async onSuccess(data) {
      void queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });

export const useRechargeMutation = () =>
  useMutation({
    mutationFn: recharge,

    onSuccess: async () =>
      Promise.all([
        void queryClient.invalidateQueries({
          queryKey: ["transaction"],
        }),
        void queryClient.invalidateQueries({
          queryKey: ["user"],
        }),
      ]),
  });
