import axios from "../../lib/ClientApi";

export const getMe = async () => axios.get("/me").then((res) => res.data);

export const updateProfile = async ({ phone }) =>
  axios
    .put("/me", {
      phone,
    })
    .then((res) => res.data);

export const updatePassword = async ({ old_password, new_password }) =>
  axios
    .put("/me/update-password", {
      old_password,
      new_password,
    })
    .then((res) => res.data);

export const recharge = async ({ telco, amount, serial, code }) =>
  axios
    .post("/recharge", {
      telco,
      amount,
      serial,
      code,
    })
    .then((res) => res.data);
