import { Container } from "@mui/material";
import React from "react";
import { useResponsive } from "../../hooks/use-responsive";
import { pxToRem } from "../../theme/typography";
import Grid from "@mui/system/Unstable_Grid/Grid";
import AccountNavbar from "./components/account-navbar";

const LayoutCustomer = ({ children }) => {
  const lgMd = useResponsive("up", "md");
  return (
    <>
      {lgMd && (
        <Container
          maxWidth="lg"
          {...(lgMd && {
            sx: {
              pt: pxToRem(35),
            },
          })}
        >
          <Grid container>
            {lgMd && (
              <Grid xs={12} md={3}>
                <AccountNavbar />
              </Grid>
            )}

            <Grid xs={12} md={9}>
              {children}
            </Grid>
          </Grid>
        </Container>
      )}
      {!lgMd && children}
    </>
  );
};

export default LayoutCustomer;
