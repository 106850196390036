import { Cards } from "../constants/main";

export const checkCard = ({ type, serial, card }) => {
  // console.log("checkCard:", serial.length, card.length);
  if (type === Cards.VIETTEL) {
    if (serial.length === 11 && card.length === 13) {
      return true;
    }

    if (serial.length === 14 && card.length === 15) {
      return true;
    }
  }

  if (type === Cards.MOBIFONE && serial.length === 15 && card.length === 12) {
    return true;
  }

  if (type === Cards.VINAPHONE && serial.length === 14 && card.length === 14) {
    return true;
  }

  if (type === Cards.ZING && serial.length === 12 && card.length === 9) {
    return true;
  }

  if (
    type === Cards.GARENA &&
    (serial.length === 8 || serial.length === 9) &&
    card.length === 16
  ) {
    return true;
  }

  if (type === Cards.VIETNAMOBILE && card.length === 12) {
    if (serial.length === 11 || serial.length === 16) {
      return true;
    }
  }

  return false;
};
