import axios from "../../lib/ClientApi";

export const getListCategories = async ({
  page_size,
  page_number,
  game_type_id,
  account_type,
}) =>
  axios
    .get("/categories", {
      params: {
        page_size,
        page_number,
        game_type_id,
        account_type,
      },
    })
    .then((res) => res.data);

export const getDetailCategory = async (id) =>
  axios
    .get(`/categories/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      const res = err.response;

      if (res.status === 404) {
        window.location.href = "/404";
      }
    });
