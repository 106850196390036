import { Container } from "@mui/material";
import React from "react";

const Main = ({ children }) => {
  return (
    <Container
      sx={{
        maxWidth: "80rem !important",
        px: ".75rem !important",
        pt: 2,
      }}
    >
      {children}
    </Container>
  );
};

export default Main;
