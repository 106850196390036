import React, { useEffect, useState } from "react";
import { m } from "framer-motion";

import CustomPopover, { usePopover } from "../../components/custom-popover";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import Image from "../../components/image";
import Iconify from "../../components/iconify";
import { pxToRem } from "../../theme/typography";
import { useRouter } from "../../routers/hooks";
import { HOME, paths } from "../../routers/paths";
import { RouterLink } from "../../routers/components";
import { useResponsive } from "../../hooks/use-responsive";
import { useGetMeQuery } from "../../core/user/user.query";
import axios from "../../lib/ClientApi";
import { fCurrency } from "../../utils/format-number";

const AccountPopover = () => {
  const popover = usePopover();

  const [token, setToken] = useState(sessionStorage.getItem("accessToken"));

  useEffect(() => {
    setToken(sessionStorage.getItem("accessToken"));
  }, []);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }, [token]);

  const lgUp = useResponsive("up", "lg");

  const { data } = useGetMeQuery();

  const [user, setUser] = useState(null);

  const theme = useTheme();

  const [isLoading, setisLoading] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (!data) return;
    setUser(data);
  }, [data]);

  useEffect(() => {
    if (!popover.open) return;
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, [popover.open]);

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    router.push(HOME);
  };

  return (
    <>
      <Box display={"flex"}>
        <Button
          component={m.button}
          startIcon={
            lgUp && (
              <ListItemAvatar
                sx={{
                  width: 40,
                  height: 40,
                  mr: 1,
                }}
              >
                {user && (
                  <Avatar
                    src={user.avatar}
                    alt={user.username}
                    sx={{
                      width: 1,
                      height: 1,
                    }}
                    variant="circular"
                  >
                    {user.username.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </ListItemAvatar>
            )
          }
          endIcon={
            lgUp && (
              <Iconify icon="bx:caret-down" sx={{ width: 14, height: 14 }} />
            )
          }
          onClick={popover.onOpen}
          sx={{
            textAlign: "left",
            width: 1,
            ...(!lgUp && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            }),
          }}
        >
          {lgUp && (
            <ListItemText
              primary={user?.username}
              primaryTypographyProps={{
                variant: "body2",
              }}
              secondary={`${fCurrency(user?.balance)} đ`}
              secondaryTypographyProps={{
                variant: "subtitle2",
                color: "primary",
              }}
            />
          )}
          {!lgUp && (
            <Iconify
              icon="bx:menu"
              width={24}
              sx={{
                color: "primary.main",
              }}
            />
          )}
        </Button>
      </Box>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          width: 200,
          p: 0,
          backgroundImage: "none",
          bgcolor: "white",
          "& .MuiPopover-paper": {
            backgroundImage: "none",
            bgcolor: "white",
          },
        }}
      >
        <Box sx={{ p: 1, pb: 2 }}>
          <Box p={pxToRem(8)}>
            {isLoading && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  width: 1,
                  height: 1,
                  py: 2,
                }}
              >
                <CircularProgress color="primary" size={24} />
              </Box>
            )}
            {!isLoading && (
              <Stack direction={"column"}>
                <Typography component="span" variant="body2" noWrap>
                  <strong>ID: </strong>
                  {user?.id}
                </Typography>
                <Typography component="span" variant="body2" noWrap>
                  <strong>Số dư: </strong>
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    {fCurrency(user?.balance)}{" "}
                    <span style={{ fontWeight: "normal" }}>đ</span>
                  </span>
                </Typography>
                {/* <Typography component="span" variant="body2" noWrap>
                  <strong>Coin: </strong>
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    {fCurrency(user?.coin) || 0}
                  </span>
                </Typography> */}
              </Stack>
            )}
          </Box>
          <Divider />
          <List>
            <ListItem
              sx={{
                p: 0,
              }}
            >
              <ListItemButton
                LinkComponent={RouterLink}
                href={paths.customer.profile}
                sx={{
                  px: 1,
                }}
              >
                <ListItemText
                  primary="Quản lý tài khoản"
                  primaryTypographyProps={{
                    variant: "caption",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{
                p: pxToRem(8),
              }}
            >
              <ListItemText
                primary="Lịch sử"
                primaryTypographyProps={{
                  variant: "overline",
                  color: "primary",
                  fontWeight: 600,
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                p: 0,
              }}
            >
              <ListItemButton
                LinkComponent={RouterLink}
                href={paths.customer.transaction}
                sx={{
                  px: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: 1,
                  }}
                >
                  <Iconify
                    icon="bxs:chevron-right"
                    sx={{
                      width: 14,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Lịch sử nạp tiền"
                  primaryTypographyProps={{
                    variant: "caption",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{
                p: 0,
              }}
            >
              <ListItemButton
                LinkComponent={RouterLink}
                href={paths.customer.nicks}
                sx={{
                  px: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    mr: 1,
                  }}
                >
                  <Iconify
                    icon="bxs:chevron-right"
                    sx={{
                      width: 14,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Mua tài khoản (nick)"
                  primaryTypographyProps={{
                    variant: "caption",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{
                p: pxToRem(8),
              }}
            >
              <ListItemText
                primary="Khác"
                primaryTypographyProps={{
                  variant: "overline",
                  color: "primary",
                  fontWeight: 600,
                }}
              />
            </ListItem>
            <ListItem
              sx={{
                p: 0,
              }}
            >
              <ListItemButton
                sx={{
                  px: 1,
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    mr: 1,
                  }}
                >
                  <Iconify
                    icon="bxs:chevron-right"
                    sx={{
                      width: 14,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Đăng xuất"
                  primaryTypographyProps={{
                    variant: "caption",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </CustomPopover>
    </>
  );
};

export default AccountPopover;
