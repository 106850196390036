import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import Iconify from "../iconify";
import { pxToRem } from "../../theme/typography";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CustomDialog = ({ title, size, content, open, onClose, ...other }) => {
  return (
    <Dialog
      fullWidth
      maxWidth={size || "sm"}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      {...other}
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 1,
        },
      }}
    >
      <DialogTitle
        display={"flex"}
        sx={{ pb: 0 }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography component="span" variant="body1" fontSize={pxToRem(18)}>
          {title}
        </Typography>
        <IconButton
          color="secondary"
          edge="start"
          onClick={onClose}
          sx={{
            color: "secondary",
            "&:hover": {
              color: "primary.main",
              bgcolor: "transparent",
            },
          }}
        >
          <Iconify icon="mingcute:close-line" sx={{ width: 18, height: 18 }} />
        </IconButton>
      </DialogTitle>

      {content && (
        <DialogContent sx={{ typography: "body2" }}> {content} </DialogContent>
      )}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  title: PropTypes.node,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  content: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CustomDialog;
