// scrollbar
import "simplebar-react/dist/simplebar.min.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lightbox
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "react-lazy-load-image-component/src/effects/blur.css";

import "react-datepicker/dist/react-datepicker.css";

import { RouterProvider } from "react-router-dom";
import "./App.css";
import { routes } from "./routers/routes";
import ThemeProvider from "./theme";
import { MotionLazy } from "./components/animate/motion-lazy";
import { SettingsProvider } from "./components/settings";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import SnackbarProvider from "./components/snackbar/snackbar-provider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      staleTime: 5 * 60 * 1000,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      // console.error(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      // console.error(error);
    },
  }),
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SettingsProvider
          defaultSettings={{
            themeMode: "light", // 'light' | 'dark'
            themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <RouterProvider router={routes} />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
