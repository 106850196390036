import { Container, Typography } from "@mui/material";
import React from "react";
import { pxToRem } from "../../theme/typography";

const Notification = () => {
  return (
    <Container
      sx={{
        maxWidth: "80rem !important",
        px: ".75rem !important",
        pt: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: pxToRem(14),
          wordBreak: "break-word",
          py: 1,
        }}
      >
        <span
          style={{
            color: "#0d0d0d",
          }}
        >
          🔥
        </span>
        <span
          style={{
            color: "#e64c4c",
          }}
        >
          <strong>Siêu Sự Kiện </strong>
        </span>
        <span
          style={{
            color: "#0d0d0d",
          }}
        >
          <strong>: Chào Hè, Đón Sale - Tài Khoản </strong>
        </span>
        <span
          style={{
            color: "#e64c4c",
          }}
        >
          <strong>Ngon </strong>
        </span>
        <span
          style={{
            color: "#0d0d0d",
          }}
        >
          <strong>, Giảm Giá </strong>
        </span>
        <span
          style={{
            color: "#e64c4c",
          }}
        >
          <strong>Shock</strong>
        </span>
      </Typography>
    </Container>
  );
};

export default Notification;
