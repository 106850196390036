import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomDialog from "../../components/custom-dialog/custom-dialog";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { useBoolean } from "../../hooks/use-boolean";
import Iconify from "../../components/iconify";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useLoginMutation,
  useRegisterMutation,
} from "../../core/auth/auth.query";
import { setSession } from "../../lib/utils";

import { useSnackbar } from "../../components/snackbar";

const loginSchema = yup.object().shape({
  username: yup.string().required("Tên tài khoản không được để trống"),
  password: yup.string().required("Mật khẩu không được để trống"),
});

const registerSchema = yup.object().shape({
  username: yup.string().required("Tên tài khoản không được để trống"),
  password: yup.string().required("Mật khẩu không được để trống"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Mật khẩu không khớp"),
});

const AuthDialog = ({ open, onClose }) => {
  const password = useBoolean();
  const confirmPassword = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const [isRegister, setIsRegister] = useState(false);

  const { mutateAsync: mutationLogin, data: dataLogin } = useLoginMutation();
  const { mutateAsync: mutateRegister, data: dataRegister } =
    useRegisterMutation();

  useEffect(() => {
    if (!dataLogin) return;
    setSession(dataLogin.access_token);
    onClose();
  }, [dataLogin]);

  useEffect(() => {
    if (!dataRegister) return;
    // console.log(dataRegister);
    sessionStorage.setItem("accessToken", dataRegister.access_token);

    onClose();
  }, [dataRegister]);

  const defaultValues = !isRegister
    ? {
        username: "",
        password: "",
      }
    : {
        username: "",
        password: "",
      };

  const methods = useForm({
    resolver: yupResolver(isRegister ? registerSchema : loginSchema),
    mode: "onTouched",
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isRegister) {
        mutateRegister({
          password: data.password,
          username: data.username,
        })
          .then((res) => {
            enqueueSnackbar("Đăng ký thành công", {
              variant: "success",
            });
          })
          .catch((err) => {
            enqueueSnackbar("Đăng ký thất bại", {
              variant: "error",
            });
          });
      } else {
        mutationLogin({
          password: data.password,
          username: data.username,
        })
          .then((res) => {
            enqueueSnackbar("Đăng nhập thành công", {
              variant: "success",
            });
          })
          .catch((err) => {
            enqueueSnackbar("Đăng nhập thất bại", {
              variant: "error",
            });
          });
      }
    } catch (error) {
      // console.error(error);
      enqueueSnackbar("Có lỗi xảy ra", {
        variant: "error",
      });
    }
  });

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      size={"xs"}
      content={
        <>
          <Stack spacing={3} py={4} px={1}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 600 }}
              textAlign={"center"}
              textTransform={"uppercase"}
            >
              {!isRegister ? "Đăng nhập" : "Đăng ký"} tài khoản
            </Typography>

            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <RHFTextField name="username" placeholder="Tên tài khoản" />
                  <RHFTextField
                    name="password"
                    placeholder="Mật khẩu"
                    type={password.value ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={password.onToggle} edge="end">
                            <Iconify
                              icon={
                                password.value
                                  ? "solar:eye-bold"
                                  : "solar:eye-closed-bold"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {isRegister && (
                    <RHFTextField
                      name="confirm_password"
                      placeholder="Nhập lại mật khẩu"
                      type={confirmPassword.value ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={confirmPassword.onToggle}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  confirmPassword.value
                                    ? "solar:eye-bold"
                                    : "solar:eye-closed-bold"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Stack>
                <Box>
                  <Stack spacing={2}>
                    <LoadingButton
                      fullWidth
                      color="primary"
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                    >
                      {!isRegister ? "Đăng nhập" : "Đăng ký"}
                    </LoadingButton>
                    <Button
                      type="button"
                      onClick={() => setIsRegister(!isRegister)}
                      variant="outlined"
                      size="large"
                      sx={{
                        borderColor: (theme) => theme.palette.secondary.light,
                        color: (theme) => theme.palette.secondary.main,
                        "&:hover": {
                          borderColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.main,
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      {!isRegister ? "Đăng ký" : "Đăng nhập"} tài khoản
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </FormProvider>
          </Stack>
        </>
      }
    />
  );
};

AuthDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AuthDialog;
