import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useResponsive } from "../../hooks/use-responsive";
import Image from "../../components/image/image";
import PropTypes from "prop-types";
import { bgGradient, textGradient } from "../../theme/css";
import SvgColor from "../../components/svg-color/svg-color";

const list = [...Array(4).keys()].map((index) => ({
  id: index + 1,
  icon: ["shopping-bag", "check-shield", "phone-call", "like"][index],
  title: [
    "Sản phẩm, dịch vụ đa dạng, cập nhật liên tục.",
    "Hàng ngàn khách hàng tin tưởng, ủng hộ.",
    "Trung tâm hỗ trợ nhanh chóng, tận tình 24/7.",
    "Giá rẻ, uy tín, chất lượng nhất thị trường.",
  ][index],
}));

const Footer = () => {
  const lgUp = useResponsive("up", "lg");

  return (
    <>
      {lgUp && (
        <Box
          sx={{
            width: 1,
            bgcolor: "background.default",
          }}
        >
          <Container
            sx={{
              maxWidth: "80rem !important",
              px: ".75rem !important",
            }}
          >
            <Stack direction={"row"} alignItems={"center"} py={1.5}>
              <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                Hỗ trợ các phương thức nạp:
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Image
                  src={"/images/card2.png"}
                  alt={"card"}
                  sx={{ height: 1, width: 1, maxWidth: 24 }}
                />
                <Typography component="span" variant="body2">
                  Thẻ cào
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Box>
      )}

      <Box
        sx={{
          width: 1,
          bgcolor: (theme) => theme.palette.grey[200],
        }}
      >
        <Container
          sx={{
            maxWidth: "80rem !important",
            px: ".75rem !important",
            py: "2rem !important",
          }}
        >
          <Box
            px={"0.5rem"}
            display={"grid"}
            gap={2}
            columnGap={2}
            gridTemplateColumns={{
              lg: "repeat(4, 1fr)",
              xs: "repeat(2, 1fr)",
            }}
          >
            {list.map((item) => (
              <Item key={item.id} {...item} />
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;

const Item = ({ icon, title }) => {
  return (
    <Stack
      spacing={0.25}
      px={"1.25rem"}
      py={"1rem"}
      bgcolor={"white"}
      sx={{
        borderRadius: 1,
      }}
    >
      <Stack direction={"row"} justifyContent={"center"}>
        <Box
          sx={{
            "& .svg-color": {
              ...bgGradient({
                direction: "270deg",
                startColor: "#ef4444",
                endColor: "#ec4899",
              }),
            },
          }}
        >
          <SvgColor
            src={`/icon/ic_${icon}.svg`}
            sx={{
              width: 60,
              height: 60,
            }}
          />
        </Box>
      </Stack>
      <Typography
        component="span"
        variant="subtitle1"
        textAlign={"center"}
        color={"primary"}
      >
        {title}
      </Typography>
    </Stack>
  );
};

Item.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
};
