import { Outlet, createBrowserRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { Suspense, lazy } from "react";
import { LoadingScreen } from "../components/loading-screen";
import LayoutCustomer from "../layouts/customer/Layout";

// import Home from "../pages/Home";

const HomePage = lazy(() => import("../pages/Home"));
const CategoryPage = lazy(() => import("../pages/category"));
const AccountListPage = lazy(() => import("../pages/account/list"));
const AccountDetailPage = lazy(() => import("../pages/account/detail"));
const SettingAccountPage = lazy(() => import("../pages/dashboard/setting"));
const NotificationPage = lazy(() => import("../pages/dashboard/notification"));
const TransactionPage = lazy(() => import("../pages/dashboard/transaction"));
const HistoryBuyPage = lazy(() => import("../pages/dashboard/history-buy"));
const CutomerPage = lazy(() => import("../pages/dashboard/customer"));
const Page404 = lazy(() => import("../pages/404"));

export const routes = createBrowserRouter([
  {
    path: "",
    element: (
      <Layout>
        <Suspense fallback={<LoadingScreen></LoadingScreen>}>
          <Outlet></Outlet>
        </Suspense>
      </Layout>
    ),
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: <HomePage></HomePage>,
      },
      {
        path: "kham-pha/",
        children: [
          {
            path: ":id",
            element: <CategoryPage></CategoryPage>,
          },
        ],
      },
      {
        path: "tai-khoan/",
        children: [
          {
            path: ":id",
            element: <AccountListPage></AccountListPage>,
          },
          {
            path: "chi-tiet/:id",
            element: <AccountDetailPage></AccountDetailPage>,
          },
        ],
      },
      {
        path: "customer",
        element: (
          <LayoutCustomer>
            <Suspense fallback={<LoadingScreen></LoadingScreen>}>
              <Outlet></Outlet>
            </Suspense>
          </LayoutCustomer>
        ),
        children: [
          {
            index: true,
            element: <CutomerPage></CutomerPage>,
          },
          {
            path: "profile",
            element: <SettingAccountPage></SettingAccountPage>,
          },
          {
            path: "notifications",
            element: <NotificationPage></NotificationPage>,
          },
          {
            path: "transactions",
            element: <TransactionPage></TransactionPage>,
          },
          {
            path: "nicks",
            element: <HistoryBuyPage></HistoryBuyPage>,
          },
        ],
      },
    ],
  },
]);
